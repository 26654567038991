import { gql, useMutation } from '@apollo/client';
import { Alert, Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

const RESET_PASSWORD = gql`
  mutation requestVerifyTokenForgotPassword($email: String!, $token: String!, $password: String!) {
    request_verify_token_forgot_password(params: { email: $email, token: $token, password: $password }) {
      error
    }
  }
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 400,
  width: '100%',
  margin: '0 auto',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');

  const [resetPassword, { loading: isLoading }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data.request_verify_token_forgot_password.error) {
        setError(data.request_verify_token_forgot_password.error);
        setIsSuccess(false);
      } else {
        setError('');
        setIsSuccess(true);
        setPassword('');
        setConfirmPassword('');
      }
    },
    onError: (error) => {
      setError('パスワードのリセットに失敗しました。もう一度お試しください。');
      setIsSuccess(false);
      console.error('Reset password error:', error);
    },
  });

  const validateForm = () => {
    if (password.length < 6) {
      setError('パスワードは6文字以上で入力してください');
      return false;
    }
    if (password !== confirmPassword) {
      setError('パスワードが一致しません');
      return false;
    }
    return true;
  };

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      setError('');
      setIsSuccess(false);

      if (!token) {
        setError('無効なリセットトークンです');
        return;
      }

      if (!email) {
        setError('メールアドレスが必要です');
        return;
      }

      if (!validateForm()) {
        return;
      }

      try {
        await resetPassword({
          variables: {
            email,
            token,
            password,
          },
        });
      } catch (err) {
        // Error handling is done in onError callback
      }
    },
    [token, email, password, confirmPassword, resetPassword],
  );

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        p: 2,
      }}
    >
      <StyledPaper elevation={3}>
        <Typography variant="h5" align="center" gutterBottom>
          パスワードリセット
        </Typography>

        <Typography variant="body2" align="center" color="text.secondary" sx={{ mb: 3 }}>
          新しいパスワードを入力してください
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {isSuccess && (
          <Alert severity="success" sx={{ mb: 3 }}>
            パスワードを変更しました。新しいパスワードでログインしてください。
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <FormContainer>
            <TextField label="メールアドレス" type="email" value={email || ''} disabled fullWidth variant="outlined" />

            <TextField
              label="新しいパスワード"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={isLoading}
              fullWidth
              variant="outlined"
              placeholder="新しいパスワードを入力"
            />

            <TextField
              label="パスワードの確認"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={isLoading}
              fullWidth
              variant="outlined"
              placeholder="新しいパスワードを再入力"
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isLoading}
              size="large"
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
            >
              {isLoading ? 'パスワードを変更中...' : 'パスワードを変更'}
            </Button>
          </FormContainer>
        </form>
      </StyledPaper>
    </Box>
  );
};

export default ResetPasswordPage;
